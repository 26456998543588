import { getConfig } from '../../utils'
import { Option } from '../utils/form'

/* Returns a tuple with the permission id and a boolean indicating if 
     it should be negated */
export const parsePermissionConditional = (conditional: string) => {
  const isNegated = conditional.startsWith('!')
  return [conditional.slice(isNegated ? 1 : 0, conditional.length), isNegated] as const
}

export interface SalesDivision {
  id: string
  name: string
  market: string
  businessArea: string
}

export const markets: Option[] = [
  {
    title: 'Denmark',
    value: 'DK',
  },
  {
    title: 'Finland',
    value: 'FI',
  },
  {
    title: 'Norway',
    value: 'NO',
  },
  {
    title: 'Sweden',
    value: 'SE',
  },
  {
    title: 'Export',
    value: 'Export',
  },
]

export enum SalesStatisticsAvailability {
  SHOW = 'EXTERNAL',
  HIDE = 'INTERNAL',
}

export type SalesStatisticsAvailabilityOption = Option & {
  value: SalesStatisticsAvailability
  isShown: boolean
}

export const salesStatistics: SalesStatisticsAvailabilityOption[] = [
  {
    title: 'Hide',
    value: SalesStatisticsAvailability.HIDE,
    isShown: false,
  },
  {
    title: 'Show',
    value: SalesStatisticsAvailability.SHOW,
    isShown: true,
  },
]

export type PartnerLevelOption = {
  title: string
  value: string
}

export const partnerLevels: PartnerLevelOption[] = [
  {
    title: 'PL1',
    value: 'PL1',
  },
  {
    title: 'PL2',
    value: 'PL2',
  },
  {
    title: 'PL3',
    value: 'PL3',
  },
  {
    title: 'PL4',
    value: 'PL4',
  },
  {
    title: 'PL5',
    value: 'PL5',
  },
]

export const availableCountries: Option[] = [
  {
    title: 'Sweden',
    value: 'SE',
  },
  {
    title: 'Finland',
    value: 'FI',
  },
  {
    title: 'Denmark',
    value: 'DK',
  },
  {
    title: 'Norway',
    value: 'NO',
  },
  {
    title: 'Czechia',
    value: 'CZ',
  },
  {
    title: 'Slovenia',
    value: 'SI',
  },
  {
    title: 'Netherlands',
    value: 'NL',
  },
  {
    title: 'Romania',
    value: 'RO',
  },
  {
    title: 'Belgium',
    value: 'BE',
  },
  {
    title: 'Estonia',
    value: 'EE',
  },
  {
    title: 'Latvia',
    value: 'LV',
  },
  {
    title: 'Poland',
    value: 'PL',
  },
  {
    title: 'Germany',
    value: 'GE',
  },
  {
    title: 'Moldova',
    value: 'MD',
  },
  {
    title: 'Spain',
    value: 'ES',
  },
  {
    title: 'Ireland',
    value: 'IE',
  },
  {
    title: 'France',
    value: 'FR',
  },
  {
    title: 'Bulgaria',
    value: 'BG',
  },
  {
    title: 'Faroe Islands',
    value: 'FO',
  },
  {
    title: 'Unknown',
    value: 'Unknown',
  },
]

const ASSIGN_PERMISSIONS = [
  'partnerlogin.assign',
  'online.assign',
  'onlinedev.assign',
  'troubleshoot.assign',
  'fleetmanager.assign',
]

const config = getConfig()

type AppInfo = {
  id: string
  name: string
  permissionsPath: {
    GET: string
    PUT: (partnerId: string, userId: string, application: string) => string
    PUTUSERGROUP: (partnerId: string, userId: string, application: string) => string
    ADAPTER_GET: (groupId: string) => string
    ADAPTERS_GET: string
  }
  aliases: string[]
  webURLs: Array<{
    siteName: string
    url: string
  }>
  permissionBadgeVisibleRule: (permissions: string[]) => boolean
  suspendedRule: (permissions: string[]) => boolean
  excludePermissions?: string[]
  otherApplicationPermissions?: {
    [applicationId in string]: string[]
  }
  permissionsExcludedFromPredefinedRoles?: string[]
}
const apps: AppInfo[] = [
  {
    id: 'partnerlogin',
    name: 'Partnerlogin/OnSite',
    permissionsPath: {
      GET: 'role/roles/application/partnerlogin/definition',
      PUT: (partnerId: string, userId: string, application: string) =>
        `role/roles/partners/${partnerId}/users/${userId}/applications/${application}`,
    },
    aliases: ['partnerlogin'],
    webURLs: [
      {
        siteName: 'Partnerlogin',
        url: config?.appURLS.PARTNER_LOGIN_URL,
      },
    ],
    permissionBadgeVisibleRule: (permissions) =>
      permissions.includes('pl_acc') || permissions.includes('onsite_acc'),
    suspendedRule: (permissions) => permissions.includes('suspended'),
    otherApplicationPermissions: {
      ciam: ['partnerlogin.assign'],
    },
  },
  // Provides dynamic typing for feature-toggled applications
  {
    id: 'online',
    name: 'Online',
    permissionsPath: {
      GET: 'role/roles/application/online/definition',
      PUT: (partnerId: string, userId: string, application: string) =>
        `role/roles/partners/${partnerId}/users/${userId}/applications/${application}`,
    },
    aliases: [],
    webURLs: [
      {
        siteName: 'Online',
        url: config?.appURLS.ONLINE_URL,
      },
    ],
    permissionBadgeVisibleRule: () => true,
    suspendedRule: (permissions) => permissions.includes('suspended'),
    otherApplicationPermissions: {
      ciam: ['online.assign'],
    },
  },
  config.featureFlags.TOGGLE_THERMIA_ONLINE_DEV_PERMISSIONS && {
    id: 'onlinedev',
    name: 'Onlinedev',
    permissionsPath: {
      GET: 'role/roles/application/onlinedev/definition',
      PUT: (partnerId: string, userId: string, application: string) =>
        `role/roles/partners/${partnerId}/users/${userId}/applications/${application}`,
    },
    aliases: [],
    webURLs: [
      {
        siteName: 'Online',
        url: config?.appURLS.ONLINE_URL,
      },
    ],
    permissionBadgeVisibleRule: () => true,
    suspendedRule: (permissions) => permissions.includes('suspended'),
    otherApplicationPermissions: {
      ciam: ['onlinedev.assign'],
    },
  },
  {
    id: 'ciam',
    name: 'CIAM',
    permissionsPath: {
      GET: 'role/roles/application/ciam/definition',
      PUT: (partnerId: string, userId: string, application: string) =>
        `role/roles/partners/${partnerId}/users/${userId}/applications/${application}`,
    },
    aliases: ['ciam'],
    webURLs: [{ siteName: 'CIAM', url: config?.appURLS.CIAM_URL }],
    permissionBadgeVisibleRule: (permissions) => {
      const filteredPermissions = permissions.filter((perm) => !ASSIGN_PERMISSIONS.includes(perm))
      return filteredPermissions.length > 0 ? true : false
    },
    suspendedRule: (permissions) => permissions.includes('suspended'),
    excludePermissions: ASSIGN_PERMISSIONS,
    permissionsExcludedFromPredefinedRoles: ['online.assign'],
  },
  config.featureFlags.TOGGLE_THERMIA_FLEET_MANAGER_PERMISSIONS && {
    id: 'fleetmanager',
    name: 'Fleet Manager',
    permissionsPath: {
      GET: 'role/roles/application/fleetmanager/definition',
      PUT: (partnerId: string, userId: string, application: string) =>
        `role/roles/partners/${partnerId}/users/${userId}/applications/${application}`,
    },
    aliases: [],
    webURLs: [],
    permissionBadgeVisibleRule: () => true,
    suspendedRule: (permissions) => permissions.includes('suspended'),
    otherApplicationPermissions: {
      ciam: ['fleetmanager.assign'],
    },
  },
]

// Filters falsy values left by un-toggled apps
export const APPLICATIONS = apps.filter(Boolean)

export type Application = (typeof APPLICATIONS)[number]['id']
export type ApplicationOption = {
  title: (typeof APPLICATIONS)[number]['name']
  value: Application
}
